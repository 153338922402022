<template>
  <div v-if="this.novedad">
    <ConfirmDialog
      ref="confirmDialog"
      :dialogText="dialogText"
      :dialogTitle="dialogTitle"
      :dialogYes="dialogYes"
      :dialogNo="dialogNo"
      :id="this.novedad.id"
      @dialog-yes="handleDialogYes"
    ></ConfirmDialog>
    <v-card>
      <v-container v-if="error">
        <v-row>
          <v-col>
            <v-alert dense type="error">{{ error }}</v-alert>
          </v-col>
        </v-row>
      </v-container>
      <v-row>
        <v-col cols="8">
          <v-card-title class="person-name">
            {{ this.novedad.get_tipo_display }}:
            {{ this.novedad.motivo.__str__ }}
          </v-card-title>
          <v-card-subtitle>
            {{ this.novedad.__str__ }}
          </v-card-subtitle>
        </v-col>
        <v-spacer> </v-spacer>
      </v-row>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col cols="8">
            <br />
            <p><strong>Motivo: </strong>{{ this.novedad.motivo.__str__ }}</p>
            <p><strong>Desde: </strong>{{ this.novedad.desde }}</p>
            <p><strong>Hasta: </strong>{{ this.novedad.hasta }}</p>
            <p v-if="this.novedad.observaciones">
              <strong>Observaciones: </strong>{{ this.novedad.observaciones }}
            </p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :to="'/novedad/' + this.novedad.id + '/editar'"
          ><v-icon left darf>mdi-pencil</v-icon>Editar</v-btn
        >
        <v-btn color="error" @click="handleDialog"
          ><v-icon left darf>mdi-delete</v-icon>Eliminar</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import ConfirmDialog from "./ConfirmDialog.vue";
import PresentismoService from "@/services/PresentismoService.js";

export default {
  name: "NovedadCard",
  components: {
    ConfirmDialog,
  },
  data() {
    return {
      loading: true,
      dialogTitle: "Confirmar Eliminar",
      dialogText: "¿Desea eliminar la novedad?",
      dialogYes: "Eliminar",
      dialogNo: "Cancelar",
      dialogId: null,
      error: "",
    };
  },
  created() {
    if (this.novedad.get_tipo_display == "Licencia")
      this.dialogText = "¿Desea eliminar la licencia?";
    else if (this.novedad.get_tipo_display == "Justificación")
      this.dialogText = "¿Desea eliminar la justificación?";
    else if (this.novedad.get_tipo_display == "Horas Autorizadas")
      this.dialogText = "¿Desea eliminar las horas extras autorizadas?";
  },
  methods: {
    handleDialog() {
      this.$refs.confirmDialog.dialog = true;
      this.dialogId = this.novedad.id;
    },
    handleDialogYes(id) {
      //console.log(id);
      PresentismoService.deleteNovedad(id)
        .then((response) => {
          this.$router.push({
            name: "Novedades",
          });
        })
        .catch((error) => {
          if (error.response.status === 404)
            this.error = "Error: Registro no encontrado.";
          else if (error.response.status === 405)
            this.error = "Error: El registro está protegido.";
        });
    },
  },
  props: {
    novedad: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.person-name {
  word-break: break-word;
}
.photo-container {
  margin-right: 15px;
}

.photo-viewport {
  position: absolute;
  top: 0px;
  width: 140%;
}
.access-detail-p {
  font-size: 1.5em;
}

.access-detail-blk {
  margin-top: 12px;
}

.access-subtitle {
  margin-left: 15px;
}

.access-detail-phones {
  font-size: 1.5em;
  margin-left: 30px;
}
</style>
